function AboutMe(props) {
  return (
    <div>
      <div>{props.props.content[0].value}</div>
      <br />
    </div>
  );
}

export default AboutMe;
