import "./Sidebar.css";

function Skillbar() {
  
return (
    <div className="skill-bar">
    <div className="skill-rank">rank</div> 
    </div>
  );
}

export default Skillbar;